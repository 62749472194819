/* eslint-disable no-undef */
import React from "react";

import { Row, Col, Table } from "reactstrap";

const rowTable = ({ chave, valor }) => (
  <tr>
    <td>{chave}</td>
    <td>{valor}</td>
  </tr>
);

const maskValue = (cents) => {
  if (cents !== undefined) {
    const val = (cents / 100).toFixed(2);
    return `R$ ${val}`.replace(".", ",");
  }
  return `R$ 0,00`;
};

const maskDocument = (document) => {
  if (document !== undefined && document !== null) {
    if (document.length === 11)
      return `${document.substring(0, 3)}.${document.substring(
        3,
        6
      )}.${document.substring(6, 9)}-${document.substring(9, 11)}`;
    if (document.length === 14)
      return `${document.substring(0, 2)}.${document.substring(
        2,
        5
      )}.${document.substring(5, 8)}/${document.substring(
        8,
        12
      )}-${document.substring(12, 14)}`;
  }
  return "";
};

const maskBarcode = (billetCode) => {
  if (billetCode !== undefined) {
    const r = (s) => <b style={{ color: "#e1a14e" }}>{s}</b>;
    const n = (s) => <b>{s}</b>;

    if (billetCode.substring(0, 1) === "8")
      return (
        <div>
          {n(billetCode.substring(0, 11))}
          {r(`${billetCode.substring(11, 12)} `)}
          {n(billetCode.substring(12, 23))}
          {r(`${billetCode.substring(23, 24)} `)}
          {n(billetCode.substring(24, 35))}
          {r(`${billetCode.substring(35, 36)} `)}
          {n(billetCode.substring(36, 47))}
          {r(`${billetCode.substring(47, 48)} `)}
        </div>
      );

    return (
      <div>
        {n(`${billetCode.substring(0, 5)}.${billetCode.substring(5, 9)}`)}
        {r(`${billetCode.substring(9, 10)} `)}
        {n(`${billetCode.substring(10, 15)}.${billetCode.substring(15, 20)}`)}
        {r(`${billetCode.substring(20, 21)} `)}
        {n(`${billetCode.substring(21, 26)}.${billetCode.substring(26, 31)}`)}
        {r(`${billetCode.substring(31, 32)} `)}
        {r(` ${billetCode.substring(32, 33)} `)}
        {n(billetCode.substring(33, 47))}
      </div>
    );
  }
  return "";
};

const BarcodeInfoComponent = ({ barcodeData }) => {
  const {
    bankName,
    discountValue,
    expirationDate,
    extraValue,
    originalValue,
    payerDocument,
    payerName,
    recipientDocument,
    recipientName,
    value,
    writableLine,
  } = barcodeData;
  return (
    <div className="content">
      <Row>
        <Col>
          <Row>
            <Col>
              <h5>Informações do documento</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table>
                <tbody>
                  {writableLine !== null ?
                    rowTable({
                      chave: "Código de barras",
                      valor: maskBarcode(writableLine),
                    }) : ""
                  }
                  {bankName !== null ? rowTable({ chave: "Banco", valor: bankName }) : ""}
                  {rowTable({
                    chave: "Nome do destinatário",
                    valor: recipientName,
                  })}
                  {recipientDocument !== null ?
                    rowTable({
                      chave: "Documento do destinatário",
                      valor: maskDocument(recipientDocument),
                    }) : ""
                  }
                  {expirationDate !== "0001-01-01T00:00:00" ?
                    rowTable({
                      chave: "Vencimento",
                      valor: `${expirationDate.substring(8, 10)}/${expirationDate.substring(5, 7)}/${expirationDate.substring(0, 4)}`,
                    }) : ""
                  }
                  {rowTable({
                    chave: "Valor do documento",
                    valor: maskValue(originalValue),
                  })}
                  {rowTable({
                    chave: "Descontos",
                    valor: maskValue(discountValue),
                  })}
                  {rowTable({
                    chave: "Acréscimos",
                    valor: maskValue(extraValue),
                  })}
                  {rowTable({
                    chave: "Valor Total",
                    valor: maskValue(value),
                  })}
                  {payerName !== null ?
                    rowTable({
                      chave: "Nome do pagador",
                      valor: payerName,
                    }) : ""
                  }
                  {payerDocument !== null ?
                    rowTable({
                      chave: "Documento do pagador",
                      valor: maskDocument(payerDocument),
                    }) : ""
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default BarcodeInfoComponent;
