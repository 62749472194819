/* eslint-disable import/order */
/* eslint-disable react/sort-comp */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
import React from "react";
import { inject } from "mobx-react";
import { getBrand, getCep } from "~/helpers/creditCard";
import { formatCentsToRealStr } from "../../helpers/monetary";

import AlertComponent from "~/components/AlertComponent";
import { CardHeader, Card, Row, Col } from "reactstrap";
import PaymentComponent from "./components/PaymentComponent";
import ValidationComponent from "./components/ValidationComponent";
import PaymentMethodComponent from "./components/PaymentMethodComponent";
import ReceiptComponent from "./components/ReceiptComponent";
import { Section } from "./styles";
import { CHARGE_TYPE, CHARGE_STATUS } from "../../configs/constants";

const routeDocumentInfo = 1;
const routePaymentMethod = 2;
const routePayment = 3;
const routeReceipt = 4;

@inject("ChargeStore")
class PaymentContainer extends React.Component {
  constructor(props) {
    super(props);
    this.toValidation = this.toValidation.bind(this);
    this.toPayment = this.toPayment.bind(this);
    this.toPaymentMethod = this.toPaymentMethod.bind(this);
    this.getToken = this.getToken.bind(this);
    this.pay = this.pay.bind(this);
    this.state = {
      validationObject: {},
      paymentOption: {},
      paymentResponse: {},
      route: routeDocumentInfo,
      isLoading: true,
      iconUrl: "",
      identificationMessage: "",
    };
  }

  componentDidMount() {
    (async () => {
      try {
        const { ChargeStore } = this.props;
        const token = this.getToken();
        const newState = { isLoading: false };
        if (token != null && token !== undefined && token !== "") {
          newState.validationObject = await ChargeStore.tokenValidation(token);         
          if (newState.validationObject.type === CHARGE_TYPE.charge && newState.validationObject.barcodeValidate != null) {
            newState.validationObject.barcodeValidate.writableLine = null;
          }

          newState.iconUrl = newState.validationObject.iconUrl;
          newState.identificationMessage = newState.validationObject.identificationMessage;
          newState.personDocument = newState.validationObject.personDocument;
          newState.personName = newState.validationObject.personName;
          newState.originalValue = newState.validationObject.originalValue;
          newState.expirationDateStr = newState.validationObject.expirationDateStr;
          newState.value = newState.validationObject.value;
          newState.status =  newState.validationObject.status;
        }
        this.setState(newState);
        if ((newState.validationObject.type === CHARGE_TYPE.chargePartner || newState.validationObject.type === CHARGE_TYPE.chargeAccount) && newState.validationObject.status === CHARGE_STATUS.waiting) {
          this.toPaymentMethod();
        }
      } catch (e) {
        this.setState({ isLoading: false });
      }
    })();
  }

  getToken() {
    const query = location.search.slice(1);
    const parts = query.split("?");
    return parts[0];
  }

  async pay(info) {
    try {
      this.setState({
        isLoading: true,
      });
      const token = this.getToken();
      const newState = {
        isLoading: false,
      };
      let isSuccess = false;
      if (token != null && token !== undefined && token !== "") {
        const { ChargeStore } = this.props;
        const { paymentOption } = this.state;
        const data = await ChargeStore.pay(
          {
            ...info,
            value: paymentOption.valueCharged,
            installments: paymentOption.installments,
          },
          token
        );
        if (data != null && data !== undefined) {
          if (data.status === 2) {
            isSuccess = true;
            if (data.redirectLink !== null && data.redirectLink !== "") {
              let url = data.redirectLink;
              if (!url.includes("http:") && !url.includes("https:"))
                url = `http://${url}`;
              window.location.replace(url);
            }
            newState.route = routeReceipt;
            newState.paymentResponse = data;
          } else AlertComponent.error(data.error);
        } else AlertComponent.error("Erro ao finalizar o pagamento.");
      } else AlertComponent.error("Token inválido.");

      try {
        /*
        caso a página de cobrança esteja 
        dentro de um Iframe
      */
        window.parent.postMessage({
          event_id: 'onFinishPayment',
          success: isSuccess
        }, '*');
      } catch (_) { }

      this.setState(newState);
    } catch (e) {
      try {
        /*
        caso a página de cobrança esteja 
        dentro de um Iframe
      */
        window.parent.postMessage({
          event_id: 'onFinishPayment',
          success: false
        }, '*');
      } catch (_) { }
      AlertComponent.error("Erro ao finalizar o pagamento.");
      this.setState({
        isLoading: false,
      });
    }
  }

  toValidation() {
    this.setState({
      route: routeDocumentInfo,
    });
  }

  toPaymentMethod() {
    this.setState({
      route: routePaymentMethod,
    });
  }

  toPayment(option) {
    this.setState({
      route: routePayment,
      paymentOption: option,
    });
  }

  getRoute() {
    const {
      route,
      validationObject,
      paymentOption,
      isLoading,
      paymentResponse,
    } = this.state;
    switch (route) {
      case routeDocumentInfo:
        return (
          <ValidationComponent
            validationObject={validationObject}
            isLoading={isLoading}
            toPaymentMethod={this.toPaymentMethod}
          />
        );

      case routePaymentMethod:
        return (
          <PaymentMethodComponent
            fundingOptions={validationObject.fundingOptions}
            isLoading={isLoading}
            toValidation={this.toValidation}
            toPayment={this.toPayment}
            chargeType={validationObject.type}
          />
        );

      case routePayment:
        return (
          <PaymentComponent
            isLoading={isLoading}
            getCep={getCep}
            getBrand={getBrand}
            paymentOption={paymentOption}
            toPaymentMethod={this.toPaymentMethod}
            pay={this.pay}
          />
        );

      case routeReceipt:
        return (
          <ReceiptComponent
            isLoading={isLoading}
            paymentResponse={paymentResponse}
            toPayment={this.toPayment}
          />
        );

      default:
        return (
          <ValidationComponent
            validationObject={validationObject}
            isLoading={isLoading}
            toPaymentMethod={this.toPaymentMethod}
          />
        );
    }
  }

  render() {
    const { isLoading, iconUrl, identificationMessage, personDocument, personName, expirationDateStr, status, originalValue  } = this.state;

    if (isLoading === true) return this.getRoute();

    return (
      <Section className="sectionChild">
        <Row className="sectionChild">
          <Col>
            <Card>
              <CardHeader style={{ position: "relative" }}>
                <Row>
                  { status === CHARGE_STATUS.waiting ?
                  (
                    <Col xs="5">
                      <img
                        alt="..."
                        src={iconUrl}
                      />
                    </Col>
                  ):(<></>)} 
                  { status === CHARGE_STATUS.waiting ?
                  (
                    <Col xs="7">
                      <Row style={{ textAlign: "right", display: "block", marginRight: -10, fontSize: 16 }}> Cobrança: {identificationMessage}</Row>
                      <Row style={{ textAlign: "right", display: "block", marginRight: -10, fontSize: 16 }}> Pagador: {personName}</Row>
                      <Row style={{ textAlign: "right", display: "block", marginRight: -10, fontSize: 16 }}> Documento: {personDocument}</Row>
                      <Row style={{ textAlign: "right", display: "block", marginRight: -10, fontSize: 16 }}> Vencimento: {expirationDateStr}</Row>
                      <Row style={{ textAlign: "right", display: "block", marginRight: -10, fontSize: 16 }}> Valor original: {formatCentsToRealStr(originalValue)}</Row>
                    </Col>
                  ):(<></>)} 
                </Row>
              </CardHeader>
              {this.getRoute()}
            </Card>
          </Col>
        </Row>
      </Section>
    );
  }
}



export default PaymentContainer;