import axios from "axios";

const brands = [
  {
    name: "Elo",
    maxNumber: 16,
    numbers:
      "636368,438935,504175,451416,509048,509067,509049,509069,509050,509074,509068,509040,509045,509051,509046,509066,509047,509042,509052,509043,509064,509040,36297,5067,4576,4011",
    icon: undefined,
  },
  {
    name: "Diners",
    maxNumber: 16,
    numbers: "301,305,36",
    icon: undefined,
  },
  {
    name: "Discover",
    maxNumber: 16,
    numbers: "6011,622,64,65",
    icon: undefined,
  },
  {
    name: "Amex",
    maxNumber: 15,
    numbers: "34,37",
    icon: undefined,
  },
  {
    name: "Hipercard",
    maxNumber: 19,
    numbers: "38,60,63",
    icon: undefined,
  },
  {
    name: "JCB",
    maxNumber: 16,
    numbers: "35,",
    icon: undefined,
  },
  {
    name: "Aura",
    maxNumber: 16,
    numbers: "50,",
    icon: undefined,
  },
  {
    name: "Visa",
    maxNumber: 16,
    numbers: "4,",
    icon: undefined,
  },
  {
    name: "Mastercard",
    maxNumber: 16,
    numbers: "5,",
    icon: undefined,
  },
];

export const getBrand = (cardNumber, callback) => {
  try {
    brands.forEach((brand) => {
      brand.numbers.split(",").forEach((currentValue) => {
        const nameExp = new RegExp(`^${currentValue}`);
        if (
          currentValue != null &&
          currentValue !== undefined &&
          currentValue !== "" &&
          nameExp.test(cardNumber)
        ) {
          callback(brand);
        }
      });
    });
  } catch (e) {
    callback(null);
  }
};

export const getCep = async (cep) => {
  const api = axios.create({
    baseURL: `https://viacep.com.br/ws/`,
  });
  const { data, status } = await api.get(`${cep}/json/`);
  if (status === 200) return data;
  return {};
};
 