import React, { Component } from "react";
import { inject } from "mobx-react";
import AlertComponent from "~/components/AlertComponent";
import LoadingComponent from "~/components/LoadingComponent";
import ChargeBarcodeComponent from "./ChargeBarcodeComponent";

@inject("ChargeStore")
class ChargeBarcodeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      barcode: "",
      paymentLink: "",
      barcodeData: {},
    };

    this.onChange = this.onChange.bind(this);
    this.validateBarcode = this.validateBarcode.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value.replace(/[^0-9]/g, ""),
    });
  }

  async validateBarcode() {
    this.setState({
      isLoading: true,
    });
    // eslint-disable-next-line no-shadow
    const { ChargeStore } = this.props;
    const { barcode } = this.state;
    const data = await ChargeStore.validateBarcode(barcode);
    const newState = { isLoading: false, paymentLink: "", barcodeData: {} };
    if (data != null && data !== undefined && data.status === 0) {
      const dataLink = await ChargeStore.generate(barcode);
      newState.barcodeData = data;
      if (dataLink != null && dataLink !== undefined && dataLink.status === 0) {
        newState.paymentLink = dataLink.link;
      } else AlertComponent.error("Não foi possivel gerar o link de pagamento");
    } else AlertComponent.error("Boleto inválido");
    this.setState(newState);
  }

  render() {
    const { isLoading, barcodeData } = this.state;
    return (
      <>
        {(isLoading && <LoadingComponent />) || (
          <ChargeBarcodeComponent
            {...this.state}
            onChange={this.onChange}
            validateBarcode={this.validateBarcode}
            barcodeData={barcodeData} />
        )}
      </>
    );
  }
}

export default ChargeBarcodeContainer;
