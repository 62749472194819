import React, { Component } from "react";
import { Provider } from "mobx-react";
import Routes from "./routes";
import userStore from "./stores/UserStore";
import chargeStore from "./stores/ChargeStore";
import AlertComponent from "./components/AlertComponent";

import "./assets/theme/scss/black-dashboard-react.scss";
import "./assets/theme/demo/demo.css";
import "./assets/theme/css/nucleo-icons.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    
  }

  render() {
    return (
      <Provider UserStore={userStore} ChargeStore={chargeStore}>
        <Routes />
        <AlertComponent />
      </Provider>
    );
  }
}

export default App;
