/* eslint-disable no-plusplus */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-undef */
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { inject } from "mobx-react";
import PerfectScrollbar from "perfect-scrollbar";

import AdminNavbar from "~/components/AdminNavbar";
import Sidebar from "~/components/Sidebar";
import logo from "~/assets/logo.png";
import menus from "~/configs/menu";

let ps;

@inject("UserStore")
class HomeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "blue",
      sidebarOpened:
        document.documentElement.className.indexOf("nav-open") !== -1,
    };
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      const { mainPanel } = this.refs;
      ps = new PerfectScrollbar(mainPanel, { suppressScrollX: true });
      const tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      if (navigator.platform.indexOf("Win") > -1) {
        const tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }

  goLogout = async () => {
    const { UserStore, history } = this.props;
    UserStore.logout();
    history.push("/");
  };

  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    const { sidebarOpened } = this.state;
    document.documentElement.classList.toggle("nav-open");
    this.setState({ sidebarOpened: !sidebarOpened });
  };

  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };

  getBrandText = () => {
    const { location } = this.props;
    for (let i = 0; i < menus.length; i++) {
      if (location.pathname.indexOf(menus[i].layout + menus[i].path) !== -1) {
        return menus[i].name;
      }
    }
    return "Brand";
  };

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/home") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            // eslint-disable-next-line react/no-array-index-key
            key={key}
          />
        );
      }
      return null;
    });
  }; 

  render() {
    const { UserStore } = this.props;
    const { name, document } = UserStore.getToken();
    const { backgroundColor, sidebarOpened } = this.state;
    return (
      <>
        <div className="wrapper">
          <Sidebar
            {...this.props}
            routes={menus}
            bgColor={backgroundColor}
            logo={{
              // outterLink: "http://triacom.com.br",
              user: { name, document },
              imgSrc: logo,
            }}
            toggleSidebar={this.toggleSidebar}
          />
          <div className="main-panel" ref="mainPanel" data={backgroundColor}>
            <AdminNavbar
              {...this.props}
              brandText=""
              user={{ name, document }}
              toggleSidebar={this.toggleSidebar}
              sidebarOpened={sidebarOpened}
              goLogout={this.goLogout}
            />
            <Switch>
              {this.getRoutes(menus)}
              <Redirect from="*" to="/home/charge" />
            </Switch>
          </div>
        </div>
      </>
    );
  }
}

export default HomeContainer;
