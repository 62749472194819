/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-undef */
import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";

import BarcodeInfoComponent from "../../components/BarcodeInfo/BarcodeInfoComponent";
import { copyToClipBoard } from "../../helpers/utils";

const ChargeBarcodeComponent = ({
  onChange,
  validateBarcode,
  barcode,
  paymentLink,
  barcodeData,
}) => {
  const contentStyle = {
    overflowX: 'hidden'
  };
  const { writableLine } = barcodeData;
  return (
    <div className="content" style={contentStyle}>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <h5 className="title">Validar boleto de cobrança</h5>
            </CardHeader>
            <CardBody>
              <Form onSubmit={validateBarcode}>
                <Row>
                  <Col>
                    <FormGroup>
                      <label>Boleto</label>
                      <Input
                        name="barcode"
                        type="text"
                        placeholder="Código de barras"
                        value={barcode}
                        onChange={(e) => onChange(e)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      className="btn-fill"
                      color="primary"
                      onClick={validateBarcode}
                    >
                      Validar boleto
                    </Button>
                  </Col>
                </Row>
                {writableLine != null && writableLine !== undefined ? (
                  <>
                    <Card
                      inverse
                      color="secontay"
                      style={{
                        backgroundColor: "#1E1E2F",
                        borderColor: "#1E1E2F",
                        marginTop: "1em",
                      }}
                    >
                      <CardBody>
                        <BarcodeInfoComponent barcodeData={barcodeData} />
                        {paymentLink != null &&
                        paymentLink !== undefined &&
                        paymentLink !== "" ? (
                          <Row>
                            <Col>
                              <Form inline>
                                <FormGroup>
                                  <h4
                                    style={{
                                      marginBottom: "0px",
                                      marginRight: "1em",
                                    }}
                                  >
                                    Link para pagamento:&nbsp;
                                    <a href={paymentLink} target="_blank">
                                      Abrir
                                    </a>
                                  </h4>

                                  <Button
                                    onClick={() => copyToClipBoard(paymentLink)}
                                  >
                                    Copiar link
                                  </Button>
                                </FormGroup>
                              </Form>
                            </Col>
                          </Row>
                        ) : (
                          <></> 
                        )}
                      </CardBody>
                    </Card>
                  </>
                ) : (
                  <></>
                )}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ChargeBarcodeComponent;
