import { observable, action } from "mobx";
import authService from "../services/AuthService";
import auth from "../services/Auth";

export class UserStore {
  @observable document = "";

  @observable name = "";

  getName = () => {
    if (this.name === "") {
      const { name } = auth.getTokenData();
      this.name = name;
    }
    return this.name;
  };

  getToken = () => {
    return auth.getTokenData();
  };

  @action.bound
  login = async ({ document, password, token }) => {
    if (token != null && token !== undefined && token !== "") {
      auth.login(token);
      return {};
    }
    const data = await authService.login(document, password);
    if (data && data.access_token) {
      auth.login(data.access_token);
      this.document = document;
    }
    return data;
  };

  @action.bound
  isLogged = () => {
    return auth.isAuthenticated();
  };

  @action.bound
  logout = () => {
    this.document = "";
    this.name = "";
    auth.logout();
  };
}

const userStore = new UserStore();
export default userStore;
