/* eslint-disable no-undef */
import axios from "axios";
import auth from "./Auth";

const api = axios.create({
  baseURL: process.env.REACT_APP_NOT_SECRET_CODE,
});

api.interceptors.request.use(async (config) => {
  const token = auth.getToken();
  if (
    token &&
    (auth.isAuthenticated() ||
      (config.url.includes("connect/token") && !auth.isAuthenticated()))
  ) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;    
  }
  // window.location.reload();
  return config;
});

export const customToken = (token, byPass) => {
  const myApi = axios.create({
    baseURL: process.env.REACT_APP_NOT_SECRET_CODE,
  });

  myApi.interceptors.request.use(async (config) => {
    if (
      token &&
      (auth.isAuthenticated() ||
        (config.url.includes("connect/token") && !auth.isAuthenticated()) ||
        (byPass !== undefined && byPass === true)
      )
    ) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    }
    // window.location.reload();
    return {};
  });
  return myApi;
};

export default api;
