/* eslint-disable no-undef */
/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  NavItem,
  NavbarText,
} from "reactstrap";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      modalSearch: false,
      color: "navbar-transparent",
    };
    this.state.userOptions = this.userOptions();
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white",
      });
    } else {
      this.setState({
        color: "navbar-transparent",
      });
    }
  };

  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-transparent",
      });
    } else {
      this.setState({
        color: "bg-white",
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };

  // this function is to open the Search modal
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch,
    });
  }; 

  userOptions = () => {
    const { collapseOpen } = this.state;
    const { goLogout, user } = this.props;

    if (collapseOpen === true)
      return (
        <>
          <NavbarText>
            <div className="photo">
              <img
                alt="..."
                // eslint-disable-next-line global-require
                src={require("../../assets/theme/img/anime3.png")}
              />
            </div>{" "}
            {user.name}
          </NavbarText>
          <NavItem onClick={goLogout}>
            <NavLink>Sair</NavLink>
          </NavItem>
        </>
      );

    return (
      <UncontrolledDropdown nav>
        <DropdownToggle
          caret
          color="default"
          data-toggle="dropdown"
          nav
          onClick={(e) => e.preventDefault()}
        >
          <div className="photo">
            <img
              alt="..."
              // eslint-disable-next-line global-require
              src={require("../../assets/theme/img/anime3.png")}
            />
          </div>
          <b className="caret d-none d-lg-block d-xl-block" />
          <p className="d-lg-none">{user.name}</p>
        </DropdownToggle>
        <DropdownMenu className="dropdown-navbar" right tag="ul">
          <NavLink tag="li">
            <DropdownItem className="nav-item">{user.name}</DropdownItem>
          </NavLink>
          <DropdownItem divider tag="li" />
          <NavLink tag="li">
            <DropdownItem className="nav-item" onClick={goLogout}>
              Sair
            </DropdownItem>
          </NavLink>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  render() {
    const { color, sidebarOpened, toggleSidebar, brandText } = this.props;
    const { collapseOpen, userOptions } = this.state;

    return (
      <>
        <Navbar
          fixed="top"
          className={classNames("navbar-absolute", color)}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <div
                className={classNames("navbar-toggle d-inline", {
                  toggled: sidebarOpened,
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand href="/" onClick={this.toggleCollapse}>
                {brandText}
              </NavbarBrand>
            </div>
            <button
              aria-expanded={false}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              data-target="#navigation"
              data-toggle="collapse"
              id="navigation"
              type="button"
              onClick={() => {
                this.toggleCollapse();
              }}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse
              onEntering={() => {
                this.setState({
                  userOptions: this.userOptions(),
                });
              }}
              navbar
              isOpen={collapseOpen}
            >
              <Nav className="ml-auto" navbar>
                {userOptions}
                <li className="separator d-lg-none" />
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
