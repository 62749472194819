/* eslint-disable no-undef */
import api from "./api";
import { REACT_APP_MUNDIPAGG_URL } from "../configs/constants";

// 23793381286003029554195000063305183220000001000
export class BarcodeService {
  validateBarcode = async (barcode) => {
    const url = `${REACT_APP_MUNDIPAGG_URL}/Charge/barcodevalidate/${barcode}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };
}

const barcodeService = new BarcodeService();
export default barcodeService;
