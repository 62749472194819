/* eslint-disable no-undef */
import React, { useState } from "react";
import {
  CardBody,
  Col,
  Form,
  Label,
  Input,
  Container,
  Row,
  Button,
} from "reactstrap";
import InputMask from "react-input-mask";
import { mask as masker, unMask } from "remask";
import AlertComponent from "~/components/AlertComponent";

import LoadingComponent from "~/components/LoadingComponent";

const TextInputMask = ({ mask, onChange, ...props }) => {
  const handleChange = (ev) => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = masker(originalValue, mask);
    onChange(maskedValue);
  };

  return <Input {...props} onChange={handleChange} />;
};

const PaymentComponent = ({
  isLoading,
  // getCep,
  getBrand,
  toPaymentMethod,
  pay,
}) => {
  const [document, setDocument] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardBrand, setCardBrand] = useState("");
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [securityNumber, setSecurityNumber] = useState("");
  // const [holderCep, setHolderCep] = useState("");
  // const [holderUf, setHolderUf] = useState("");
  // const [holderCity, setHolderCity] = useState("");
  // const [holderStreet, setHolderStreet] = useState("");
  // const [holderNumber, setHolderNumber] = useState("");
  // const [personEmail, setPersonEmail] = useState("");
  const [cellphone, setCellphone] = useState("");

  return (
    <>
      {(isLoading && (
        <>
          <LoadingComponent />
        </>
      )) || (
        <CardBody>
          <Row>
            <Col>
              <h5 className="card-category">Dados do cartão de crédito</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <Container>
                <Form>
                  <Row>
                    <Col md="12">
                      <Label>Documento do titular do cartão</Label>
                      <TextInputMask
                        type="text"
                        mask={["999.999.999-99", "99.999.999/9999-99"]}
                        placeholder="CPF ou CNPJ"
                        onChange={(value) => setDocument(value)}
                        value={document}
                      />
                      {/* <Label>E-mail</Label>
                          <InputMask
                            type="mail"
                            placeholder="E-mail"
                            className="form-control"
                            onChange={(e) => setPersonEmail(e.target.value)}
                          /> */}
                      <Label>Nome do titular igual ao cartão</Label>
                      <Input
                        type="text"
                        placeholder="Nome do titular igual ao cartão"
                        onChange={(e) => setName(e.target.value)}
                      />
                      <Label>Celular</Label>
                      <TextInputMask
                        type="text"
                        name="doc"
                        mask={["(99) 9999-9999", "(99) 9 9999-9999"]}
                        placeholder="Celular"
                        onChange={(value) => setCellphone(value)}
                        value={cellphone}
                      />
                      <Label>Numero do cartão</Label>
                      <InputMask
                        type="tel"
                        placeholder="Numero do cartão"
                        mask="9999.9999.9999.9999"
                        className="form-control"
                        onChange={(e) => {
                          setCardNumber(e.target.value);
                          getBrand(e.target.value, (brand) => {
                            setCardBrand(brand.name);
                          });
                        }}
                      />
                      <Label>Bandeira</Label>
                      <Input
                        type="tel"
                        placeholder="Bandeira"
                        value={cardBrand}
                        disabled
                      />
                      <Label>Data de vencimento</Label>
                      <InputMask
                        mask="99/99"
                        type="tel"
                        placeholder="Vencimento"
                        className="form-control"
                        onChange={(e) => setDate(e.target.value)}
                      />
                      <Label>Código de segurança (CVV)</Label>
                      <Input
                        type="number"
                        placeholder="Código de segurança (CVV)"
                        onChange={(e) => setSecurityNumber(e.target.value)}
                      />
                      {/* <Label>CEP</Label>
                          <InputMask
                            type="tel"
                            mask="99999-999"
                            placeholder="CEP"
                            className="form-control"
                            onChange={async (e) => {
                              let { value } = e.target;
                              value = value.replace(/[^0-9]/g, "");
                              if (
                                value != null &&
                                value !== undefined &&
                                value.length === 8
                              ) {
                                const {
                                  uf,
                                  localidade,
                                  logradouro,
                                  complemento,
                                  bairro,
                                } = await getCep(value.replace("-", ""));
                                setHolderCep(value.replace("-", ""));
                                setHolderUf(uf);
                                setHolderCity(localidade);
                                setHolderStreet(
                                  `${logradouro} - ${bairro} - ${complemento}`
                                );
                              }
                            }}
                          />
                          <Label>Estado (UF)</Label>
                          <Input
                            type="text"
                            placeholder="Estado (UF)"
                            onChange={(e) => setHolderUf(e.target.value)}
                            value={holderUf}
                          />
                          <Label>Cidade</Label>
                          <Input
                            type="text"
                            placeholder="Cidade"
                            onChange={(e) => setHolderCity(e.target.value)}
                            value={holderCity}
                          />
                          <Label>Rua</Label>
                          <Input
                            type="text"
                            placeholder="Rua"
                            onChange={(e) => setHolderStreet(e.target.value)}
                            value={holderStreet}
                          />
                          <Label>Numero da residência</Label>
                          <Input
                            type="number"
                            placeholder="Numero da residência"
                            onChange={(e) => setHolderNumber(e.target.value)}
                          /> */}
                    </Col>
                  </Row>
                </Form>
              </Container>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                className="btn-fill"
                color="danger"
                onClick={toPaymentMethod}
                type="button"
              >
                Voltar
              </Button>
              <Button
                className="btn-fill"
                color="primary"
                onClick={() => {
                  if (
                    document !== undefined &&
                    document !== "" &&
                    cardNumber !== undefined &&
                    cardNumber !== "" &&
                    name !== undefined &&
                    name !== "" &&
                    date !== undefined &&
                    date !== "" &&
                    securityNumber !== undefined &&
                    securityNumber !== "" &&
                    cellphone !== undefined &&
                    cellphone !== ""
                  ) {
                    const card = {
                      holderDocument: document.replace(/[^0-9]/g, ""),
                      number: cardNumber.replace(/[^0-9]/g, ""),
                      holderName: name,
                      expMonth: parseInt(date.split("/")[0], 10),
                      expYear: parseInt(date.split("/")[1], 10),
                      cvv: securityNumber,
                    };
                    // if (holderCep !== undefined && holderCep.trim() !== "")
                    //   card.holderCep = holderCep;
                    // if (holderUf !== undefined && holderUf.trim() !== "")
                    //   card.holderUf = holderUf;
                    // if (holderCity !== undefined && holderCity.trim() !== "")
                    //   card.holderCity = holderCity;
                    // if (
                    //   holderStreet !== undefined &&
                    //   holderStreet.trim().replace("-", "") !== ""
                    // )
                    //   card.holderStreet = holderStreet;
                    // if (
                    //   holderNumber !== undefined &&
                    //   holderNumber.trim() !== ""
                    // )
                    //   card.holderStreet += ` - ${holderNumber}`;

                    const info = {
                      personDocument: document.replace(/[^0-9]/g, ""),
                      personName: name,
                      cellphone: cellphone.replace(/[^0-9]/g, ""),
                      card,
                    };
                    // if (personEmail !== undefined && personEmail.trim() !== "")
                    //   info.personEmail = personEmail;
                    pay(info);
                  } else
                    AlertComponent.error("Há campos obrigatórios faltando.");
                }}
                type="button"
              >
                Pagar
              </Button>
            </Col>
          </Row>
        </CardBody>
      )}
    </>
  );
};

export default PaymentComponent;
