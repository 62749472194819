/* eslint-disable react/no-string-refs */
/* eslint-disable no-underscore-dangle */
import React, { Component } from "react";
import NotificationAlert from "react-notification-alert";

class AlertComponent extends Component {
  static sucess(message) {
    AlertComponent.__singletonRef.__sucess(message);
  }

  static error(message) {
    AlertComponent.__singletonRef.__error(message);
  }

  constructor(props) {
    super(props);
    AlertComponent.__singletonRef = this;
  }

  alert(message, type) {
    const options = {
      place: "tr",
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 60,
    };
    this.refs.notificationAlert.notificationAlert(options);
  }

  __sucess(message) {
    this.alert(message, "success");
  }

  __error(message) {
    this.alert(message, "danger");
  }

  render() {
    return (
      <div className="react-notification-alert-container">
        <NotificationAlert  ref="notificationAlert" />
      </div>
    );
  }
}

export default AlertComponent;
