export const URL_AUTH_BASE_PATH = process.env.REACT_APP_AUTH_URL;
export const URL_BARCODE_BASE_PATH = process.env.REACT_APP_BARCODE_URL;
export const REACT_APP_MUNDIPAGG_URL = process.env.REACT_APP_MUNDIPAGG_URL;

export const AUTH_DATA = {
  client_id: "Triacom", // This is the client_id
  client_secret: "TriacomSecret0101", // This is the client_secret
  grant_type: "password",
  scope: "triacom",
};

export const TOKEN_KEY = "@triacom-web";
export const TOKEN_EXPIRES_KEY = "@triacom-exp-web";

export const EXPIRE_CAHCE = 60000;

export const CHARGE_TYPE = {
  charge: 1,
  chargePartner: 2,
  chargeAccount: 3
};

export const CHARGE_STATUS = {
  waiting: 0,
  pending: 1,
  success: 2,
  error: 3,
  refund: 4
};