import { action } from "mobx";
import barcodeService from "../services/BarcodeService";
import mundipaggService from "../services/MundipaggService";

export class ChargeStore {
  @action.bound
  validateBarcode = async (barcode) => {
    const data = await barcodeService.validateBarcode(barcode);
    return data;
  };

  @action.bound
  generate = async (barcode) => {
    const data = await mundipaggService.generate(barcode);
    return data;
  };

  @action.bound
  tokenValidation = async (token) => {
    const data = await mundipaggService.tokenValidation(token);
    return data;
  };
  
  @action.bound
  pay = async (info, token) => {
    const data = await mundipaggService.pay(info, token);
    return data;
  };
}
const chargeStore = new ChargeStore();
export default chargeStore;
