/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;

  .sectionChild{
    width: 90%;
    margin: 0;
  }
`;
