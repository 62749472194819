/* eslint-disable no-undef */
import React from "react";

import { Card, CardFooter, Button } from "reactstrap";

import LoadingComponent from "~/components/LoadingComponent";

import BarcodeInfoComponent from "../../../components/BarcodeInfo/BarcodeInfoComponent";

const ValidationComponent = ({
  validationObject,
  isLoading,
  toPaymentMethod,
}) => {
  const { barcodeValidate, status, error } =
    validationObject !== undefined ? validationObject : {};
  return (
    <>
      {(isLoading && (
        <>
          <LoadingComponent />
        </>
      )) || (
          <Card>
            <CardFooter>
              {status === 0 &&
                barcodeValidate != null &&
                barcodeValidate !== undefined ? (
                <BarcodeInfoComponent barcodeData={barcodeValidate} />
              ) : (
                <p>{error}</p>
              )}
              {status === 0 ? (
                <Button color="primary" onClick={toPaymentMethod}>
                  Continuar
                </Button>
              ) : (
                <></>
              )}
            </CardFooter>
          </Card>
  )
}
    </>
  );
};

export default ValidationComponent;
