/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { useState } from "react";

import { CardBody, Button, Row, Col } from "reactstrap";

import LoadingComponent from "~/components/LoadingComponent";

import { makeReceipt } from "../../../helpers/utils";

const ReceiptComponent = ({ isLoading, paymentResponse, toPayment }) => {
  const { paymentReceipt, status, type, iconUrl } = paymentResponse;
  const [base64, setBase64] = useState("");

  makeReceipt(paymentReceipt, type, iconUrl, (myBase64) => {
    setBase64(myBase64);
  });

  return (
    <>
      {(isLoading && (
        <>
          <LoadingComponent />
        </>
      )) || (
        <CardBody>
          <Row>
            <Col>
              <h5 className="title">
                {status === 2
                  ? "Pagamento realizado com sucesso"
                  : "Erro ao pagar"}
              </h5>
            </Col>
          </Row>
          <Row>
            <Col>{status === 2 ? <img src={base64} /> : <></>}</Col>
          </Row>
          <Row>
            <Col>
              {status === 2 ? (
                <Button
                  color="primary"
                  onClick={() => {
                    const link = document.createElement("a");
                    link.setAttribute("href", base64);
                    link.setAttribute("download", "comprovante.png");
                    link.click();
                  }}
                >
                  Baixar comprovante
                </Button>
              ) : (
                <Button color="primary" onClick={toPayment}>
                  Tentar novamente
                </Button>
              )}
            </Col>
          </Row>
        </CardBody>
      )}
    </>
  );
};

export default ReceiptComponent;
