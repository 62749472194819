/* eslint-disable no-undef */
import React from "react";

import {
  CardBody,
  Row,
  Col,
  Button,
} from "reactstrap";

import LoadingComponent from "~/components/LoadingComponent";
import { CHARGE_TYPE } from "../../../configs/constants";

const ButtomOption = ({ option, selectOption }) => {
  try {
    if (option == null || option === undefined || option.valuePlot === 0)
      return <Col />;

    return (
      <Col>
        <Button
          className="btn-fill"
          color="secondary"
          onClick={() => selectOption(option)}
          type="button"
          style={{ width: "100%" }}
        >
          {option.installments}x de R${" "}
          {`${(parseInt(option.valuePlot, 10) / 100).toFixed(2)}`.replace(
            ".",
            ","
          )}
        </Button>
      </Col>
    );
  } catch (e) {
    return <Col />;
  }
};

const PaymentMethodComponent = ({
  fundingOptions,
  isLoading,
  toValidation,
  toPayment,
  chargeType,
}) => {
  const listOptions = [];
  const fOptions = JSON.parse(JSON.stringify(fundingOptions)).filter(
    (option) => {
      return !(option === undefined || option.valuePlot === 0);
    }
  );

  for (let i = 0; i <= fOptions.length; i += 2) {
    listOptions.push(
      // <Row key={JSON.stringify(fOptions[i])}>
      <Row key={i}>
        <ButtomOption selectOption={toPayment} option={fOptions[i]} />
        <ButtomOption selectOption={toPayment} option={fOptions[i + 1]} />
      </Row>
    );
  }

  return (
    <>
      {(isLoading && (
        <>
          <LoadingComponent />
        </>
      )) || (
        <CardBody>
          <Row>
            <Col>
              <h5 className="title">Modo de pagamento</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              {listOptions.map((currentValue) => {
                return currentValue;
              })}
            </Col>
          </Row>
          {(chargeType !== CHARGE_TYPE.chargePartner && chargeType !== CHARGE_TYPE.chargeAccount) ?
          <Row>
            <Col>
              <Button color="danger" onClick={toValidation}>
                Voltar
              </Button>
            </Col>
          </Row>
          : ""}
        </CardBody>
      )}
    </>
  );
};

export default PaymentMethodComponent;
