/* eslint-disable no-undef */
import qs from "querystring";
import api from "./api";
import { AUTH_DATA, URL_AUTH_BASE_PATH } from "../configs/constants";

export class AuthService {
  login = async (document, password) => {
    const requestBody = {
      ...AUTH_DATA,
      username: document,
      password,
    };

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const url = `${URL_AUTH_BASE_PATH}/connect/token`;
    try {
      const { data } = await api.post(url, qs.stringify(requestBody), config);
      return data;
    } catch (err) {
      return null;
    }
  };
}

const authService = new AuthService();
export default authService;
