/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-undef */
import jwtDecode from "jwt-decode";
import { TOKEN_KEY, TOKEN_EXPIRES_KEY } from "../configs/constants";
import { getTokenUrl } from "../helpers/utils";

export class Auth {
  isAuthenticated = () => {
    
    try {
      const myToken = getTokenUrl();
      if (myToken != null && myToken !== undefined && myToken !== "") {
        const { exp } = jwtDecode(myToken);

        localStorage.setItem(TOKEN_KEY, myToken);
        localStorage.setItem(TOKEN_EXPIRES_KEY, exp);
      }

      const token = localStorage.getItem(TOKEN_KEY);
      if (token == null) return false;
      const { exp } = jwtDecode(token);
      return exp > new Date().getTime() / 1000;
    } catch (e) {
      return false;
    }
  };

  getToken = () => localStorage.getItem(TOKEN_KEY);

  login = (token) => {
    const { exp } = jwtDecode(token);
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(TOKEN_EXPIRES_KEY, exp);
  };

  getTokenData = () => jwtDecode(localStorage.getItem(TOKEN_KEY));

  logout = () => {
    localStorage.removeItem(TOKEN_KEY);
  };
}

const auth = new Auth();
export default auth;
