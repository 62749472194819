/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 100px;
`;
