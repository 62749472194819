/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
import logo from "../assets/logo2.png";
import logoCooperMobil from "../assets/logocoopermobil.png";
import logoGenial from "../assets/logogenial.png";
import { CHARGE_TYPE } from "../configs/constants";

export const makeReceipt = (text, type, iconUrl, onCreated) => {
  const list = text.split("\n");
  const canvas = document.createElement("canvas");
  canvas.width = 410;
  canvas.height = list.length * 23 + 55 + 46;
  const ctx = canvas.getContext("2d");
  ctx.fillStyle = "#222222";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  const imgObj = new Image();
  imgObj.src = logo;

  if(type === CHARGE_TYPE.chargePartner && iconUrl.includes("genial")) 
    imgObj.src = logoGenial;
  if(type === CHARGE_TYPE.chargePartner && iconUrl.includes("coopermobil")) 
    imgObj.src = logoCooperMobil;
    
  imgObj.onload = () => {
    ctx.drawImage(imgObj, canvas.width / 2 - 100, 23, 200, 55);

    ctx.fillStyle = "#fff";
    ctx.font = "15px Arial";
    ctx.textAlign = "center";
    list.forEach((iten, index) => {
      ctx.fillText(iten, canvas.width/2, index * 23 + 55 + 46);
    });
    onCreated(canvas.toDataURL("image/png"));
  };
};

const cpfFormater = (cpf) => {
  return `${cpf.substring(0, 3)}.${cpf.substring(3, 6)}.${cpf.substring(
    6,
    9
  )}-${cpf.substring(9, 11)}`;
};

const cnpjFormater = (cpf) => {
  return `${cpf.substring(0, 2)}.${cpf.substring(2, 5)}.${cpf.substring(
    5,
    8
  )}/${cpf.substring(8, 12)}-${cpf.substring(12, 14)}`;
};

export const documentFormater = (document) => {
  if (document != null) {
    if (document.length === 11) return cpfFormater(document);
    if (document.length === 14) return cnpjFormater(document);
  }
  return "";
};

export const getTokenUrl = () => {
  const query = location.search.slice(1);
  const parts = query.split("?");
  return parts[0];
}

export const copyToClipBoard = async (copyMe) => {
  const parentOption = (text)=>{
    window.parent.postMessage(`copyToClipBoard:${text}`, '*');
  }

  try {
    navigator.clipboard.writeText(copyMe).then(
      () => AlertComponent.sucess("Link Copiado"),
      (err) => {
        try{
          parentOption(copyMe);
        } catch(e){
          AlertComponent.error("Link não Copiado")
        }
        
      }
    );
  } catch (err) {
    try{
      parentOption(copyMe);
    } catch(e){
      AlertComponent.error("Link não Copiado")
    }
  }
};