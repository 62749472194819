/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
import React, { Component } from "react";
import { inject } from "mobx-react";
import AlertComponent from "~/components/AlertComponent";
import LoadingComponent from "~/components/LoadingComponent";
import LoginComponent from "./LoginComponent";

@inject("UserStore")
class LoginContainer extends Component {
  state = {
    document: "",
    password: "",
    error: "",
    isLoading: false,
  };

  constructor(props) {
    super(props);
    const { UserStore, history } = props;
    if (UserStore.isLogged()) history.push("/home");
    this.goLogin = this.goLogin.bind(this);
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  goLogin = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      const { UserStore, history } = this.props;
      const { document, password } = this.state;
      await UserStore.login({
        document,
        password,
      });
      this.setState({
        isLoading: false,
      });
      if (UserStore.isLogged()) history.push("/home");
      else AlertComponent.error("Falha ao realizar login", "danger");
    } catch (_) {}
  };

  render() {
    const { isLoading } = this.state;
    return (
      <>
        {(isLoading && <LoadingComponent />) || (
          <LoginComponent
            {...this.state}
            onChange={this.onChange}
            goLogin={this.goLogin}
          />
        )}
      </>
    );
  }
}

export default LoginContainer;
