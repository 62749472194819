import React from "react";
import PropTypes from "prop-types";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import { Section } from "./styles";

const LoginComponent = ({ onChange, goLogin, document, password }) => (
  <Section>
    <div className="content">
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <h5 className="title">Bem vindo</h5>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col>
                    <FormGroup>
                      <label>Documento</label>
                      <Input
                        id="document"
                        name="document"
                        value={document}
                        onChange={(e) => onChange(e)}
                        placeholder="CPF/CNPJ"
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label>Senha</label>
                      <Input
                        name="password"
                        type="password"
                        placeholder="Senha"
                        value={password}
                        onChange={(e) => onChange(e)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      className="btn-fill"
                      color="primary"
                      onClick={()=>goLogin()}
                    >
                      Entrar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  </Section>
);

LoginComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  goLogin: PropTypes.func.isRequired,
  document: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
};

export default LoginComponent;
