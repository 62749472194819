import ChargeBarcodeContainer from "../pages/ChargeBarcode";

const menus = [
  {
    path: "/charge",
    name: "Cobrança",
    icon: "tim-icons icon-bank",
    component: ChargeBarcodeContainer,
    layout: "/home",
  }
]; 
export default menus;
