/* eslint-disable no-undef */
import jwtDecode from "jwt-decode";
import api, { customToken } from "./api";
import { REACT_APP_MUNDIPAGG_URL } from "../configs/constants";

export class MundipaggService {
  account = async () => {
    const url = `${REACT_APP_MUNDIPAGG_URL}/Charge/account`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  generate = async (barcode) => {
    const url = `${REACT_APP_MUNDIPAGG_URL}/Charge/generate`;
    try {
      const { data } = await api.post(url, { barcode });
      return data;
    } catch (err) {
      return null;
    }
  };

  tokenValidation = async (token) => {
    const { barcode } = jwtDecode(token);
    const url = (barcode === "" ? `${REACT_APP_MUNDIPAGG_URL}/chargepartner/validation` : `${REACT_APP_MUNDIPAGG_URL}/Charge/validation`);
    try {
      const myApi = customToken(token, true);
      const { data } = await myApi.post(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  pay = async (info, token) => {
    try {
      const { barcode } = jwtDecode(token);
      const url = (barcode === "" ? `${REACT_APP_MUNDIPAGG_URL}/chargepartner/payment` : `${REACT_APP_MUNDIPAGG_URL}/Charge/payment`);
   
      const myApi = customToken(token);
      const { data } = await myApi.post(url, info);
      return data;
    } catch (err) {
      return null;
    }
  };
}

const mundipaggService = new MundipaggService();
export default mundipaggService;
